


























































import Vue from "vue";
import { Shop } from "@/constants";

export default Vue.extend({
  name: "CategoryNav",
  props: { active: Number },
  methods: {
    categoryTitle(): string {
      const activeCategory = Shop.categories.find(
        (cat) => cat.id === this.active
      );
      return activeCategory?.title ?? "Categories";
    },
  },
  data: () => ({
    Shop,
  }),
});
