import { VCard } from 'vuetify/lib/components/VCard';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,{staticClass:"categories mt-3 mt-md-0 pt-md-3 pb-md-0 px-md-16",attrs:{"no-gutters":"","justify":"center"}},[_vm._l((_vm.Shop.categories),function(category){return _c('router-link',{key:category.id,staticClass:"categories__link categories__link--web d-none d-md-inline py-5 px-13 px-lg-16",class:_vm.active == category.id ? 'categories__active' : '',attrs:{"to":{
      name: 'Category',
      params: {
        id: category.id,
      },
    }}},[_vm._v(" "+_vm._s(category.title)+" ")])}),_c(VMenu,{staticClass:"d-inline d-md-none",attrs:{"tile":"","max-width":"960"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c(VRow,_vm._g(_vm._b({staticClass:"categories__link d-flex d-md-none py-4",attrs:{"no-gutters":"","align":"center","justify":"center"}},'v-row',attrs,false),on),[_vm._v(_vm._s(_vm.categoryTitle()))])]}}])},[_c(VCard,{staticClass:"d-inline d-md-none",attrs:{"tile":""}},[_c(VList,{staticClass:"py-0",attrs:{"tile":"","color":"#015869"}},_vm._l((_vm.Shop.categories),function(category){return _c(VListItem,{key:category.id,staticClass:"px-0"},[_c('router-link',{staticClass:"categories__link categories__link--mobile py-4 text-center",class:_vm.active == category.id ? 'categories__active' : '',attrs:{"to":{
              name: 'Category',
              params: {
                id: category.id,
              },
            }}},[_vm._v(" "+_vm._s(category.title)+" ")])],1)}),1)],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }