


































































import Vue from "vue";

export default Vue.extend({
  name: "ProductCard",
  props: {
    id: Number,
    image: String,
    name: String,
    format: String,
    currency: String,
    price: Number,
  },
  data: () => ({
    dialog: false,
  }),
});
