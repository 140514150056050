import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"tile":"","width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VCard,_vm._g(_vm._b({staticClass:"product d-flex flex-column px-4 py-6 ma-4",attrs:{"height":"372","elevation":"0","color":"#1b4452","tile":""}},'v-card',attrs,false),on),[_c(VImg,{staticClass:"align-self-center",attrs:{"width":"262","height":"262","src":_vm.image},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c(VRow,{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c(VProgressCircular,{attrs:{"indeterminate":"","color":"grey lighten-5"}})],1)]},proxy:true}],null,true)}),_c(VSpacer),_c('div',{staticClass:"product__name mt-4 ml-4"},[_vm._v(_vm._s(_vm.name))]),_c('div',{staticClass:"product__format ml-4"},[_vm._v(_vm._s(_vm.format))]),_c('div',{staticClass:"product__price pb-4 ml-4"},[_vm._v(" "+_vm._s(_vm.currency)+" $"+_vm._s(_vm.price)+".00 ")])],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,{attrs:{"tile":"","color":"#015869"}},[_c(VCardActions,[_c(VCardTitle,{staticClass:"product__modal--title text-h5"},[_vm._v(" "+_vm._s(_vm.name)+" ")]),_c(VSpacer),_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){_vm.dialog = false}}},[_c(VIcon,{attrs:{"color":"white"}},[_vm._v("mdi-close")])],1)],1),_c(VCardText,[_c('p',{staticClass:"product__modal--description"},[_vm._v(" Purchasing through Spencer Krips Music is temporarily closed. Please contact me if you would like more information or to purchase directly. ")]),_c(VBtn,{staticClass:"product__modal--button my-2",attrs:{"tile":"","large":"","color":"#8a4d58","elevation":"0","to":{
          name: 'Contact',
          params: {
            subject: _vm.name,
          },
        }}},[_vm._v(" Contact me")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }